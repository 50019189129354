import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

const { protocol, host } = window.location; // Dynamically gets protocol (http/https) and host
const HOST = `${protocol}//${host}`
const createRegistrationLink = (email, otp, expiry) => `${HOST}/register?email=${email}&otp=${otp}&expiry=${expiry}`


export const GetRegistrationLinkUserTable = (params) => {
    const theme = useTheme()
    const link = createRegistrationLink(params.row.email, params.row.one_time_password, params.row.one_time_password_expiry)
    const hasRegistered = params.row.has_registered === true
    const [copied, setCopied] = useState(false)

    const handleCopyClick = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setCopied(true)
            })
    }

    const handleCopyBlur = () => {
        setCopied(false)
    }


    if (hasRegistered) return ('Registered')
    return (
        <>
            Pending
            <Tooltip title={copied ? "Copied!" : "Copy registration link"} arrow>
                <IconButton
                    sx={{ ml: "20px" }}
                    onClick={handleCopyClick}
                    onBlur={handleCopyBlur}>
                    <ContentCopyIcon sx={{ color: theme.palette.highlight.main }} />
                </IconButton>
            </Tooltip>
            <SendEmail
                email={params.row.email}
                expiry={params.row.one_time_password_expiry}
                link={link}
            />

        </>

    )
}



export const SendEmail = ({ email, link, expiry }) => {
    const theme = useTheme()
    const dateObject = new Date(expiry);
    const date = dateObject.toISOString().split('T')[0]
    const time = dateObject.toISOString().split('T')[1].split('.')[0]

    const handleSendEmail = () => {
        // Todo: add name
        // Todo: add expiry of registration
        const to = email
        const subject = 'Welcome to DebtRay';
        // Create a multi-line body with bullet points
        const body = `
        Dear Sir or Madam,

        welcome to DebtRay!
         
        Please set your password using this link:
        
        ${link}        
        
        This link is valid until ${date}, ${time} (UTC).
        
        Regards,
        
        Your DebtRay Team
        `


        const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return (
        <Tooltip title={"Send invitation"} arrow>
            <IconButton
                sx={{ ml: "20px" }}
                onClick={handleSendEmail}>
                <SendOutlinedIcon sx={{ color: theme.palette.highlight.main }} />
            </IconButton>
        </Tooltip>
    );
};

