import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Send, Visibility, VisibilityOff } from "@mui/icons-material";
import Button from '@mui/material/Button';
import GraphBox from '../components/GraphBox';
import BoxTitle from '../components/headers/BoxTitle';
import { Snack } from '../components/Snack'
import SearchCompany from '../bond_report/company/SearchCompany';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { SendEmail } from './RegistrationLink';
import fetchWithRefresh from '../helpers/api';


const createNewUser = async (credentials, setDbChanged, setOpenSnack, setSnackMessage) => {
    try {
        const response = await fetchWithRefresh('/api/auth/add_user',
            {
                method: 'POST',
                body: JSON.stringify(credentials),
                headers: { 'Content-Type': 'application/json' },
            });
        const body = await response.json()
        if (response.ok) {
            setOpenSnack(true)
            setSnackMessage({
                title: "Created new user",
                message: body.message,
                severity: 'success', 
                actionComponent: <SendEmail email={body.email}/>
            })
            setDbChanged(true)
        } else {
            setOpenSnack(true)
            setSnackMessage({
                title: "Failed creating new User",
                message: body.message,
                severity: 'error'
            })
        }
    } catch (error) {
        setOpenSnack(true)
        setSnackMessage({
            title: "Failed creating new User",
            message: "Could not submit API call",
            severity: 'error'
        })
    }
}



export default function AddUser({ setDbChanged, companies, flex }) {
    const roles = ['user', 'admin']
    const initialState = {
        email: undefined,
        password: undefined,
        role: 'user',
        name: undefined,
        surname: undefined,
        company: undefined,
    }
    const [credentials, setCredentials] = useState(initialState)
    const [showPassword, setShowPassword] = useState(false);
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState({ title: undefined, message: undefined, severity: undefined, actionComponent: null })

    const parseUndefined = (s) => { return s === undefined ? '' : s }

    const handleSubmit = () => {
        createNewUser(credentials, setDbChanged, setOpenSnack, setSnackMessage)
        setCredentials(initialState)
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handlePasswordVisibilityToggle = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleInputChange = key => event => {
        setCredentials({
            ...credentials,
            [key]: event.target.value
        });
    }

    const handleCompany = (company) => {
        setCredentials({
            ...credentials,
            company: company
        })
    }

    const handleDeleteCompany = () => {
        setCredentials({
            ...credentials,
            company: undefined
        })
    }

    return (
        <GraphBox>
            <BoxTitle title={'Add new User'} flex={flex} paddingBottom="16px" />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, marginRight: '10%' }}>

                <TextField
                    required
                    id="email"
                    label="Email"
                    value={parseUndefined(credentials.email)}
                    onChange={handleInputChange('email')}
                />

                <TextField
                    required
                    id="name"
                    label="Name"
                    value={parseUndefined(credentials.name)}
                    onChange={handleInputChange('name')}
                />

                <TextField
                    required
                    id="surname"
                    label="Surname"
                    value={parseUndefined(credentials.surname)}
                    onChange={handleInputChange('surname')}
                />

                <FormControl>
                    <InputLabel>Role</InputLabel>
                    <Select
                        label="Role"
                        id="role"
                        name="role"
                        value={credentials.role}
                        onChange={handleInputChange('role')}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <SearchCompany
                    candidates={companies}
                    onSearchResultClick={handleCompany}
                    label={'Company'}
                    width={null} />


                {credentials.company &&
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}>
                        <Chip
                            label={credentials.company}
                            display={'flex'}
                            variant="filled"
                            onDelete={handleDeleteCompany} />
                    </Stack>
                }
         
                <Button
                    className='button'
                    type="submit"
                    variant='contained'
                    onClick={handleSubmit}> Add </Button>
                <Snack
                    open={openSnack}
                    onClose={handleSnackClose}
                    severity={snackMessage.severity}
                    title={snackMessage.title}
                    message={snackMessage.message}
                />
            </Box>
        </GraphBox>
    );
}
